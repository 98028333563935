import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private _ls = new SecureLS({ encodingType: 'aes' });
  constructor() {}

  SetData(key: string, value: any, expired: number = 0) {
    localStorage.setItem(key, value);
  }
  GetData(key: string) {
    return localStorage.getItem(key);
  }
  setDataEncrypt(key: any, value: any) {
    this._ls.set(key, value);
  }
  getDataEncrypt(key: any) {
    return this._ls.get(key);
  }
  remove(key: string) {
    this._ls.remove(key);
  }
  clear() {
    this._ls.removeAll();
  }
}
