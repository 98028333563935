// tslint:disable:variable-name
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { IArimaServiceState, ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { environment } from '../../../../../environments/environment';
import { S_User } from 'src/app/Models/S_General/S_User';
import { EncryptionService } from 'src/app/services/encryption.service';
import { Injectable } from '@angular/core';


const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

const DEFAULT_ARIMA_SERVICE_STATE: IArimaServiceState = {
  module: '',
  methodName: '',
  model: '',
  postdata: {},
  map: {}
};
@Injectable({
  providedIn: 'root'
})
export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];
  public arimaServiceState$ = new BehaviorSubject<IArimaServiceState>(DEFAULT_ARIMA_SERVICE_STATE);


  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }

  protected http: HttpClient;
  protected encryption : EncryptionService
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}`;
  constructor(http: HttpClient,
    encryption : EncryptionService) {
    this.http = http;
    this.encryption = encryption
    
  }

  

  // READ (Returning filtered list of entities)
  find(tableState: ITableState
    ): Observable<TableResponseModel<T>> {
      let model: any = this.arimaServiceState$.value.model;
      let model2: any = this.arimaServiceState$.value.model2;
      let map: any = this.arimaServiceState$.value.map;
      let localStorageObject = JSON.parse(localStorage.getItem('v726demo1-authf649fc9a5f55'));
      let username = localStorageObject.Username;
      let token = localStorageObject.authToken;
      const header = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      let S_UserLogIn:S_User=this.encryption.getDataEncrypt("ArimaUser");
      let datas: any = {
        WsUserName: 'ArvenaYazilim',
        WsPassword: 'eAqqc5sumlWVlYJKTIoFsEiHRIsVCeKbUGKhyOaGUBCX9HLnTsxGCndw1j0jZuzY',
        User: S_UserLogIn.USR_Code,
        Pass: S_UserLogIn.USR_Pass,
        org: '23',
      };
      datas['filter'] = tableState.filter
      datas['paginator'] =  tableState.paginator;
      datas['sorting'] = tableState.sorting;
      datas['searchTerm'] = tableState.searchTerm;
      datas['grouping'] = tableState.grouping;
      datas['grouping'] = tableState.grouping;
      datas['search'] = tableState.searchTerm;
      if (this.arimaServiceState$.value.postdata != null) {
        header.set('Content-Type', 'application/json');
        datas[model] = [this.arimaServiceState$.value.postdata];
      }
      if (this.arimaServiceState$.value.postdata2 != null) {
        header.set('Content-Type', 'application/json');
        datas[model2] = [this.arimaServiceState$.value.postdata2];
      }
      for (let prop in map) {
        datas[prop] = map[prop];
      }
      const data = [datas];
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(
      this.API_URL + this.arimaServiceState$.value.module + '/api/' + this.arimaServiceState$.value.methodName,
    { data },{
      headers: header,
    }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ data: [], total: 0,success: 'false',message:'' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getItemById(id: number): Observable<TableResponseModel<T>> {
    let model: any = this.arimaServiceState$.value.model;
      let map: any = this.arimaServiceState$.value.map;
      let localStorageObject = JSON.parse(localStorage.getItem('v726demo1-authf649fc9a5f55'));
      let username = localStorageObject.Username;
      let token = localStorageObject.authToken;
      const header = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      let S_UserLogIn:S_User=this.encryption.getDataEncrypt("ArimaUser");
      let datas: any = {
        WsUserName: 'ArvenaYazilim',
        WsPassword: 'eAqqc5sumlWVlYJKTIoFsEiHRIsVCeKbUGKhyOaGUBCX9HLnTsxGCndw1j0jZuzY',
        User: S_UserLogIn.USR_Code,
        Pass: S_UserLogIn.USR_Pass,
        org: '23',
      };
      if (this.arimaServiceState$.value.postdata != null) {
        header.set('Content-Type', 'application/json');
        datas[model] = [this.arimaServiceState$.value.postdata];
      }
      for (let prop in map) {
        datas[prop] = map[prop];
      }
      const data = [datas];
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(
      this.API_URL + this.arimaServiceState$.value.module + '/api/' + this.arimaServiceState$.value.methodName,
    { data },{
      headers: header,
    }).pipe(
      tap((res: TableResponseModel<T>) => {
        if (!res.success) {
          this._errorMessage.next(res.message);
        }
      }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', err);
        return of({ data: [], total: 0,success: 'false',message:'' });
      }),
      finalize(() => this._isLoading$.next(false))
    )
  }

  // CREATE
  // server should return the object with ID
  create(item: T): Observable<TableResponseModel<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    let model: any = this.arimaServiceState$.value.model;
    let model2: any = this.arimaServiceState$.value.model2;
    let map: any = this.arimaServiceState$.value.map;
    let localStorageObject = JSON.parse(localStorage.getItem('v726demo1-authf649fc9a5f55'));
    let username = localStorageObject.Username;
    let token = localStorageObject.authToken;
    const header = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let S_UserLogIn:S_User=this.encryption.getDataEncrypt("ArimaUser");
    let datas: any = {
        WsUserName: 'ArvenaYazilim',
        WsPassword: 'eAqqc5sumlWVlYJKTIoFsEiHRIsVCeKbUGKhyOaGUBCX9HLnTsxGCndw1j0jZuzY',
        User: S_UserLogIn.USR_Code,
        Pass: S_UserLogIn.USR_Pass,
        org: '23',
      };
    if (this.arimaServiceState$.value.postdata != null) {
      header.set('Content-Type', 'application/json');
      datas[model] = [this.arimaServiceState$.value.postdata];
    }
    if (this.arimaServiceState$.value.postdata2 != null) {
      header.set('Content-Type', 'application/json');
      datas[model2] = [this.arimaServiceState$.value.postdata2];
    }
    for (let prop in map) {
      datas[prop] = map[prop];
    }
    const data = [datas];

    return this.http.post<TableResponseModel<T>>(
      this.API_URL + this.arimaServiceState$.value.module + '/api/' + this.arimaServiceState$.value.methodName,
    { data },{
      headers: header,
    }).pipe(
      tap((res: TableResponseModel<T>) => {
        if (!res.success) {
          this._errorMessage.next(res.message);
        }
      }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ data: [], total: 0,success: 'false',message:'' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: T): Observable<TableResponseModel<T>> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    let model: any = this.arimaServiceState$.value.model;
    let model2: any = this.arimaServiceState$.value.model2;
    let map: any = this.arimaServiceState$.value.map;
    let localStorageObject = JSON.parse(localStorage.getItem('v726demo1-authf649fc9a5f55'));
    let username = localStorageObject.Username;
    let token = localStorageObject.authToken;
    const header = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let S_UserLogIn:S_User=this.encryption.getDataEncrypt("ArimaUser");
      let datas: any = {
        WsUserName: 'ArvenaYazilim',
        WsPassword: 'eAqqc5sumlWVlYJKTIoFsEiHRIsVCeKbUGKhyOaGUBCX9HLnTsxGCndw1j0jZuzY',
        User: S_UserLogIn.USR_Code,
        Pass: S_UserLogIn.USR_Pass,
        org: '23',
      };
    if (this.arimaServiceState$.value.postdata != null) {
      header.set('Content-Type', 'application/json');
      datas[model] = [this.arimaServiceState$.value.postdata];
    }
    if (this.arimaServiceState$.value.postdata2 != null) {
      header.set('Content-Type', 'application/json');
      datas[model2] = [this.arimaServiceState$.value.postdata2];
    }
    for (let prop in map) {
      datas[prop] = map[prop];
    }
    const data = [datas];

    return this.http.post<TableResponseModel<T>>(
      this.API_URL + this.arimaServiceState$.value.module + '/api/' + this.arimaServiceState$.value.methodName,
    { data },{
      headers: header,
    }).pipe(
      tap((res: TableResponseModel<T>) => {
        if (!res.success) {
          this._errorMessage.next(res.message);
        }
      }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', err);
        return of({ data: [], total: 0,success: 'false',message:'' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/updateStatus';
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    let model: any = this.arimaServiceState$.value.model;
    let map: any = this.arimaServiceState$.value.map;
    let localStorageObject = JSON.parse(localStorage.getItem('v726demo1-authf649fc9a5f55'));
    let username = localStorageObject.Username;
    let token = localStorageObject.authToken;
    const header = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    let S_UserLogIn:S_User=this.encryption.getDataEncrypt("ArimaUser");
      let datas: any = {
        WsUserName: 'ArvenaYazilim',
        WsPassword: 'eAqqc5sumlWVlYJKTIoFsEiHRIsVCeKbUGKhyOaGUBCX9HLnTsxGCndw1j0jZuzY',
        User: S_UserLogIn.USR_Code,
        Pass: S_UserLogIn.USR_Pass,
        org: '23',
      };
    if (this.arimaServiceState$.value.postdata != null) {
      header.set('Content-Type', 'application/json');
      datas[model] = [this.arimaServiceState$.value.postdata];
    }
    for (let prop in map) {
      datas[prop] = map[prop];
    }
    const data = [datas];

    return this.http.post<TableResponseModel<T>>(
      this.API_URL + this.arimaServiceState$.value.module + '/api/' + this.arimaServiceState$.value.methodName,
    { data },{
      headers: header,
    }).pipe(
      tap((res: TableResponseModel<T>) => {
        if (!res.success) {
          this._errorMessage.next(res.message);
        }
      }),
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', err);
        return of({ data: [], total: 0,success: 'false',message:'' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/deleteItems';
    const body = { ids };
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          if (res.success) {
            this._items$.next(res.data);
            this.patchStateWithoutFetch({
              paginator: this._tableState$.value.paginator.recalculatePaginator(
                res.total
              ),
            });
          } else {
            this._errorMessage.next(res.message);
          }
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }
}
